<template>
  <div>
    <div ref="navBar">
      <UserAdministrationNavBar :isCreateUser="true" @on-back="handleBack" />
    </div>

    <b-badge class="w-100" variant="secondary" show size="sm"
      >Create New User</b-badge
    >

    <b-container class="mt-2 white-font" fluid>
      <b-row class="mx-2">
        <b-col cols="2">
          <b-row align-h="between" class="my-2">
            <b-col class="mt-1">Username* </b-col>
            <b-col cols="12"
              ><b-input-group size="sm">
                <b-form-input
                  v-model="userInfo.username"
                  placeholder="Enter Username"
                  @input="validateInput('username')"
                ></b-form-input>
              </b-input-group>
            </b-col>
            <template v-if="errors.username && errors.username.length > 0">
              <b-col cols="12">
                <span class="text-danger">{{ errors.username }}</span>
              </b-col>
            </template>
          </b-row>
          <b-row align-h="between" class="my-2">
            <b-col class="mt-1">Email* </b-col>
            <b-col cols="12"
              ><b-input-group size="sm">
                <b-form-input
                  v-model="userInfo.email"
                  placeholder="Enter Email"
                  @input="validateInput('email')"
                ></b-form-input> </b-input-group
            ></b-col>

            <template v-if="errors.email && errors.email.length > 0">
              <b-col cols="12">
                <span class="text-danger">{{ errors.email }}</span>
              </b-col>
            </template>
          </b-row>
          <b-row align-h="between" class="my-2">
            <b-col class="mt-1">First Name* </b-col>
            <b-col cols="12"
              ><b-input-group size="sm">
                <b-form-input
                  v-model="userInfo.firstName"
                  placeholder="Enter First Name"
                  @input="validateInput('firstName')"
                ></b-form-input> </b-input-group
            ></b-col>

            <template v-if="errors.firstName && errors.firstName.length > 0">
              <b-col cols="12">
                <span class="text-danger">{{ errors.firstName }}</span>
              </b-col>
            </template>
          </b-row>
          <b-row align-h="between" class="my-2">
            <b-col class="mt-1">Last Name* </b-col>
            <b-col cols="12"
              ><b-input-group size="sm">
                <b-form-input
                  v-model="userInfo.lastName"
                  placeholder="Enter Last Name"
                  @input="validateInput('lastName')"
                ></b-form-input> </b-input-group
            ></b-col>

            <template v-if="errors.lastName && errors.lastName.length > 0">
              <b-col cols="12">
                <span class="text-danger">{{ errors.lastName }}</span>
              </b-col>
            </template>
          </b-row>
          <b-row align-h="between" class="my-2">
            <b-col class="mt-1">Mobile Number*</b-col>
            <b-col cols="12"
              ><b-input-group size="sm">
                <b-form-input
                 type="number"
                  v-model="userInfo.mobileNumber"
                  placeholder="Enter Mobile Number"
                  @input="validateInput('mobileNumber')"
                  class="no-spinners"
                ></b-form-input> </b-input-group
            ></b-col>
            <template
              v-if="errors.mobileNumber && errors.mobileNumber.length > 0"
            >
              <b-col cols="12">
                <span class="text-danger">{{ errors.mobileNumber }}</span>
              </b-col>
            </template>
          </b-row>
          <b-row class="my-4" v-if="isMasterAdmin">
            <b-col cols="12">
              <b-button size="sm" block @click="userWorklistSetting(0)"  title="Add worklist settings"
                >Worklist Settings</b-button
              >
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="10"
          ><b-row class="mt-2">
            <b-table
              class="white-font"
              :fields="fields"
              :items="items"
              :small="true"
            >
              <template #cell(actions)="data">
                <b-button v-if="data.item.saved" size="sm" title="Delete row" @click="deleteRow(data.item)"
                  ><b-icon
                    icon="trash-fill"  
                  ></b-icon
                ></b-button>
                <template v-else>
                  <b-button size="sm" class="" title="Reset selection" @click="onReset(data.item)"
                    ><b-icon
                      icon="arrow-clockwise"
                    ></b-icon
                  ></b-button>
                </template>
              </template>
              <template #cell(customer)="data" v-if="isSuperAdmin">
                <b-dropdown
                  :text="
                    selectedCustomers[data.item.id]
                      ? selectedCustomers[data.item.id] + ' '
                      : 'Select customer'
                  "
                  size="sm"
                  block
                  class="dropdown user-administration-dropdown"
                >
                  <b-dropdown-item
                    href="#"
                    v-for="customer in filteredCustomers"
                    :key="customer.id"
                    :disabled="Object.values(selectedCustomers).includes(customer.name)"
                    @click="onCustomerSelect(data.item, customer)"
                    >{{ customer.name }}</b-dropdown-item
                  >
                </b-dropdown>
              </template>
              <template #cell(tenants)="data">
                <b-dropdown
                  id="dropdown-form"
                  ref="dropdown"
                  class="dropdown user-administration-dropdown"
                  :text="
                    selectedTenants[data.item.id] &&
                    selectedTenants[data.item.id].length
                      ? selectedTenants[data.item.id].length +
                        ' Tenant Selected'
                      : 'Select Tenant(s)'
                  "
                  size="sm"
                  block
                >
                  <b-dropdown-form class="w-100">
                    <b-form-checkbox-group
                      v-model="selectedTenants[data.item.id]"
                      :options="tenantsOfCustomers[data.item.id]"
                      stacked
                      @change="onTenantSelect(data.item)"
                    ></b-form-checkbox-group>
                  </b-dropdown-form>
                </b-dropdown>
              </template>
              <template #cell(permissions)="data">
                <b-dropdown
                  id="dropdown-form"
                  ref="dropdown"
                  class="dropdown user-administration-dropdown"
                  :text="totalSelectedPermisions(data.item.id)>0?totalSelectedPermisions(data.item.id)+' Permission(s) selected':'Select Permission(s)'
                  "
                  size="sm"
                  block
                >
                  <b-form-checkbox-group
                    v-model="permissionGroupSelection[data.item.id]"
                    stacked
                    class="px-3"
                  >
                    <div
                      v-for="(group, index) in allGroupedPermissions"
                      :key="index"
                    >
                      <b-form-checkbox
                        :value="group.name"
                        @change="onPermisisonGroupSelection($event, data.item.id, group)"
                      >
                        {{ group.name }}
                      </b-form-checkbox>
                      <b-form-checkbox-group
                        v-model="selectedPermissions[data.item.id][group.name]"
                        stacked
                        class="pl-4"
                        @change="onPermissionSelection($event,data.item.id,group)"
                      >
                        <b-form-checkbox
                          v-for="(permission, index) in group.permissions"
                          :key="index"
                          :value="permission.value"
                          
                        >
                         <p :title="permission.description" class="my-0">{{ permission.text }}</p> 
                        </b-form-checkbox>
                      </b-form-checkbox-group>
                    </div>
                  </b-form-checkbox-group>
                </b-dropdown>
              </template>
              <template #cell(WorklistSetting)="data">
                <b-button
                  size="sm"
                  block
                  @click="userWorklistSetting(data.item)"
                  title="Add worklist settings"
                  >Worklist Settings</b-button
                >
              </template>
            </b-table>
          </b-row>
          <b-row>
            <b-col cols="6">
              <b-button
                v-if="isSuperAdmin"
                class="mt-3"
                @click="addRow(items[items.length - 1])"
                :loading="isLoading"
                title="Add new row"
                >Add New Row</b-button
              >
            </b-col>
            <b-col cols="6" class="text-right">
              <b-button
                class="mt-3"
                @click="createKeycloakUser"
                :disabled="isLoading || !checkUserInfoFilled || !isTenantAdded()"
                variant="primary"
                title="Create user"
              >
                <b-icon
                  v-if="isLoading"
                  icon="circle-fill"
                  animation="throb"
                  font-scale="1"
                ></b-icon>
                Create</b-button
              >
            </b-col></b-row
          ></b-col
        >
      </b-row>
    </b-container>
    <UserWorklistSetting
      :userWorklistSettingsDialog="userWorklistSettingsDialog"
      @close="closeWorklistSettingModal"
      :userData="userWorklistsettingData"
      :selected-customer-id="selectedCustomersId"
      :is-create="true"
      @save-setting="saveUserSetting"
      @save-signature="saveSignature"
    />
    <b-modal title="Alert" :visible="isUnsavedChanges"  header-bg-variant="secondary"
    header-text-variant="light"
    body-bg-variant="dark"
    body-text-variant="light"
    footer-bg-variant="dark"
    footer-text-variant="light"
     @hidden="isUnsavedChanges=false"
    content-class="shadow" ok-title="Yes" @ok="goBack">You have unsaved changes. Are you sure you want to go back?</b-modal>
  </div>
</template>

<script>
import Vue from "vue";
import permissions from "../common/permissions";
import userAdministrationWebServices from "../common/userAdministrationWebServices";
import UserAdministrationNavBar from "./UserAdministrationNavBar.vue";
import UserWorklistSetting from "./UserWorklistSetting.vue";
export default {
  components: {
    UserAdministrationNavBar,
    UserWorklistSetting,
  },
  props: {
    fetchedUsers: Array,
  },
  data() {
    return {
      isUnsavedChanges:false,
      permissionGroupSelection:{0:[]},
      allSelected:{},
      allGroupedPermissions:[],
      selectedCustomersId:null,
       userWorklistSettingsDialog: false,
      selectedCustomers: {},
      searchedPermission: "",
      searchedTenant: "",
      selectedTenants: {},

      errors: {},

      isLoading: false,

      items: [
        {
          id: 0,
          tenants: {},
          profile: null,
          permissions: [],
          saved: false,
          customers: {},
        },
      ],
      userInfo: {
        username: "",
        email: "",
        firstName: "",
        lastName: "",
        mobileNumber: "",
        password: "changeit",
      },
      selectedPermissions: {
        0:{}
      },
      tenantOptions: {},
      permissionsOptions: [],
      tenantsOfCustomers: [],
      customerLevelPermisisons:[],
      userWorklistsettingData:null,
      defaultSetting:{
        "reportPhraseLut": null,
        "reportSettings": null,
        "worklistActions": [
            "viewer_full",
            "patient_history",
            "view_report"
        ],
        "worklistColumns": [
            "actions",
            "lock",
            "patient_name",
            "study_date",
            "patient_id",
            "patient_name",
            "patient_age_sex",
            "acc_num",
            "study_desc",
            "images",
            "referring_physician",
            "reported_by",
            "study_time",
            "tat"
        ],
        "worklistSettings": {
            "Today": {
                "default": true,
                "studyDateFilter": "today"
            }
        },
        "worklistAltViewers": [],
        "worklistRefreshInterval": 5,
        "worklistViewers": {
            "dbl_click_route": "viewer",
            "lite_quality": 70
        },
        "wwwlFavorites": null,

          },
      selectedWorklistsetting:{},
      seletedCustomerSignature:{},
      isFormDirty:false,
      initialFormData:{
        username: "",
        email: "",
        firstName: "",
        lastName: "",
        mobileNumber: "",
        password: "changeit",
      }
    };
  },
  async mounted() {
    this.loadTeantsAndPermissions();
  },
  computed: {
    fields() {
      if (this.isSuperAdmin) {
        return [
          { key: "actions", label: "Actions" },
          { key: "customer", label: "Customers" },
          { key: "tenants", label: "Tenants" },
          { key: "permissions", label: "Permissions" },
           { key: "WorklistSetting", label: "Worklist Settings" },
        ];
      } else {
        return [
          { key: "actions", label: "Actions" },
          { key: "tenants", label: "Tenants" },
          { key: "permissions", label: "Permissions" },

        ];
      }
    },

    filteredCustomers() {
      return this.tenantOptions;
    },
    filteredTenants() {
      return this.tenantOptions;
    },
    isSuperAdmin() {
      return permissions.isSuperAdmin();
    },
    isMasterAdmin() {
      return permissions.isMasterAdmin();
    },
     checkUserInfoFilled() {
       for (const key in this.userInfo) {
            if (!this.userInfo[key]) {
                return false;
            }
            if (key === 'email') {
                if (!this.validateEmail(this.userInfo[key])) {
                    return false;
                }
            }
    }
    return true;
  },
   isDirty() {
      for (const key in this.userInfo) {
        if (this.userInfo[key] !== this.initialFormData[key] || this.isFormDirty) {
          return true;
        }
      }
      return false;
    }
  },
  methods: {
    goBack(){
      this.$router.push('/user_administration')
    },
    handleBack(){
      if(this.isDirty){
         this.isUnsavedChanges=true
      }else{
        this.$router.push('/user_administration')
      }
    },
    totalSelectedPermisions(id){
      const selectedPermissions = [];
         for (const roleName in this.selectedPermissions[id]) {
          for (const permission of this.selectedPermissions[id][roleName]) {
            selectedPermissions.push(permission);
           }
        }
      return selectedPermissions.length
    },
    onPermisisonGroupSelection(e,id,group){
      if(e.includes(group.name)){
        const selectedPermissions=[]
        group.permissions.forEach((item)=>{
          selectedPermissions.push(item.value)
        })
        this.$set(this.selectedPermissions[id],group.name,selectedPermissions)
      }else{
        this.$set(this.selectedPermissions[id],group.name,[])
      }
      this.isFormDirty=true
      this.$forceUpdate()
    },
      onPermissionSelection(e,id,group){
      if(e.length != group.permissions.length){
        if(this.permissionGroupSelection[id] &&this.permissionGroupSelection[id].includes(group.name)){
          const index = this.permissionGroupSelection[id].indexOf(group.name);
          if (index > -1) {
            this.permissionGroupSelection[id].splice(index, 1);
          }

        }
      }
      this.isFormDirty=true

    },
    saveUserSetting(setting){
      this.selectedWorklistsetting[this.selectedCustomersId] =setting
    },
    saveSignature(signature){
    this.seletedCustomerSignature[this.selectedCustomersId] =signature
    },
    async loadTeantsAndPermissions() {
      try {
        const response =
          await userAdministrationWebServices.fetchAdminTenants();

        this.tenantOptions = response;
        if (this.isMasterAdmin) {
          const filteredTenants = this.tenantOptions.find((item)=>item.id ==this.$store.state.customerId)


          this.tenantsOfCustomers[0] = filteredTenants.tenants;
        }
      } catch (error) {
        Vue.$log.error("error::", error);
      }
      const groupedPermisisons =permissions.getAvailableGroupedTenantPermissions()
      this.allGroupedPermissions=groupedPermisisons
    },
    formatString(str){
       return str.replace(/_/g, ' ').replace(/(\w)\w*/, (match, letter) => letter.toUpperCase() + match.slice(1));
    },
    closeWorklistSettingModal() {
      this.userWorklistsettingData={}
      this.userWorklistSettingsDialog = false;
    },
    userWorklistSetting(item) {

      let selectedCustomer =null
     if(this.isSuperAdmin){
     selectedCustomer= this.tenantOptions.find((element) => element.name == this.selectedCustomers[item.id]);
     }else{
      selectedCustomer= this.tenantOptions.find((element) => element.id == this.$store.state.customerId);
     }
      if(selectedCustomer){

          this.selectedCustomersId =selectedCustomer.id
        if(Object.keys(this.selectedWorklistsetting).includes(this.selectedCustomersId)){

           this.userWorklistsettingData=this.selectedWorklistsetting[this.selectedCustomersId]
        }else{

          this.userWorklistsettingData=this.defaultSetting
        }

      this.userWorklistSettingsDialog = true;
      }else{
         this.$bvToast.toast(
          "Please select customer first",
          {
            autoHideDelay: 5000,
            solid: true,
            title: "Alert",
            variant: "info",
          }
        );
        return;
      }

    },
    onTenantSelect(item) {
      this.$set(item, this.selectedTenants[item.id], { permissions: [] });
      this.isFormDirty=true
    },
    onCustomerSelect(item, customer) {
      this.$set(item, customer.id, { permissions: [] });
      this.$set(this.selectedCustomers, item.id, customer.name);
      this.tenantsOfCustomers[item.id] = customer.tenants;
      this.selectedTenants[item.id] =[]
      this.isFormDirty=true
      this.$forceUpdate()
    },
    validateEmail(email) {
      const emailRegex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return emailRegex.test(email);
    },
  validateInput(field) {
    if (field === "email") {
        if (!this.validateEmail(this.userInfo.email)) {
            this.errors.email = "Enter a valid email";
        } else {
            delete this.errors.email;
        }
    } else {
        delete this.errors[field];
    }
   },
    validateUserInputField() {
      this.errors = {};
      let isValid = true;
      if (this.userInfo.username.trim() == "") {
        this.errors.username = "Username is required";
        isValid = false;
      }
      if (this.userInfo.email.trim() == "") {
        this.errors.email = "Email is required";
        isValid = false;
      } else if (!this.validateEmail(this.userInfo.email)) {
        this.errors.email = "Enter a valid email";
        isValid = false;
      }
      if (this.userInfo.firstName.trim() == "") {
        this.errors.firstName = "First name is required";
        isValid = false;
      }
      if (this.userInfo.lastName.trim() == "") {
        this.errors.lastName = "Last name is required";
        isValid = false;
      }
      if (this.userInfo.mobileNumber.trim() == "") {
        this.errors.mobileNumber = " Mobile number is required";
        isValid = false;
      }

      return isValid;
    },
    onReset(item) {
      this.selectedPermissions[item.id] = [];
      this.selectedTenants[item.id] = [];
    },
    addRow(currRow) {
      if (
        this.selectedTenants[currRow.id] &&
        this.selectedTenants[currRow.id].length > 0
      ) {
       this.permissionGroupSelection[currRow.id + 1]=[]
         this.selectedPermissions[currRow.id + 1]={}
        this.items.push({
          id: currRow.id + 1,
          tenants: {},
          profile: null,
          permissions: [],
          saved: false,
          customers: {},
        });
        currRow.saved = true;
      }
      if (!this.isSuperAdmin) {
        this.tenantsOfCustomers[currRow.id + 1] =
          this.tenantsOfCustomers[currRow.id];
      }
    },
    deleteRow(currRow) {
      delete this.selectedCustomers[currRow.id]
      delete this.selectedPermissions[currRow.id];
      delete this.selectedTenants[currRow.id];
      this.items = this.items.filter((eachRow) => eachRow.id !== currRow.id);
    },
    isTenantAdded() {
      if (
        this.selectedTenants[this.items[0].id] &&
        this.selectedTenants[this.items[0].id].length > 0
      ) {
        return true;
      } else {
        return false;
      }
    },
    async createKeycloakUser() {


      if (!this.validateUserInputField()) {
        return;
      }
      if (!this.isTenantAdded()) {
        this.$bvToast.toast(
          "Please add at least one tenant",
          {
            autoHideDelay: 5000,
            solid: true,
            title: "Error",
            variant: "info",
          }
        );
        return;
      }
        try {
        this.isLoading = true;
        const customers = {};
        this.items.forEach((element) => {
          if (
            this.selectedTenants[element.id] &&
            this.selectedTenants[element.id].length > 0
          ) {
              let selectedPermissions = [];
                 for (const permissionSet in this.selectedPermissions[element.id]) {
                   selectedPermissions.push(...this.selectedPermissions[element.id][permissionSet]);
                 }

              // Initialize arrays for Customer and Tenant permissions
              let customerPermissions = [];
              let tenantPermisions = [];
              // Loop through selected permissions and categorize them
                selectedPermissions.forEach(permission => {
                  // Check if the permission is in the "customer" level in the original array
                  let found = false;
                  for (const group of this.allGroupedPermissions) {
                      for (const perm of group.permissions) {
                          if (perm.value === permission && perm.level === "customer") {
                              customerPermissions.push(permission);
                              found = true;
                              break;
                          }
                      }
                      if (found) {
                          break;
                      }
                  }
                  // If not in "customer" level, add to other permissions array
                  if (!found) {
                      tenantPermisions.push(permission);
                  }
              });
            let tenantsForThisCustomer = this.selectedTenants[element.id].reduce(
              (acc, tenantId) => {
                acc[tenantId] = { permissions: tenantPermisions };
                return acc;
              },
              {}
            );
           
              let customer =null
              if(this.isMasterAdmin){
                const existingCustomersIds= Object.keys(customers)
                if(existingCustomersIds.includes(this.$store.state.customerId))
                {
                  tenantsForThisCustomer = {...customers[this.$store.state.customerId].tenants,...tenantsForThisCustomer}
                }
                customer = this.tenantOptions.find(
                     (item) => item.id == this.$store.state.customerId);
              }else{
                 customer = this.tenantOptions.find(
                    (item) => item.name == this.selectedCustomers[element.id]
                  );
              }

                  if (customer) {
                    customers[customer.id] = {
                      permissions: customerPermissions,
                      tenants: tenantsForThisCustomer,
                    };
                  }
                }
              });
              this.userInfo.customers = customers;

             const repsonse =  await userAdministrationWebServices.createUser(this.userInfo);
              await this.saveNewUserSetting(repsonse)
              this.isLoading = false;
              this.$bvToast.toast("User created successfully", {
                autoHideDelay: 5000,
                solid: true,
                title: "Success",
                variant: "success",
              });
              this.selectedPermissions = {0:{}};
              this.selectedTenants = {};
              this.userInfo = {
                username: "",
                email: "",
                firstName: "",
                lastName: "",
                mobileNumber: "",
              };
              this.items = [
                {
                  id: 0,
                  tenant: null,
                  profile: null,
                  permissions: [],
                  saved: false,
                },
              ];
              setTimeout(() => {
                this.$router.push("/user_administration");
              }, 500);
            } catch (error) {
           Vue.$log.error("Error :::::    ",error)
              this.isLoading = false;

              if (error?.response?.status == 409) {
                this.$bvToast.toast("User name already exist", {
                  autoHideDelay: 5000,
                  solid: true,
                  title: "Error",
                  variant: "error",
                });
              }
            }
    },
   async  saveNewUserSetting(user)
    {
      if(Object.keys(this.selectedWorklistsetting).length>0){

        for (const key in this.selectedWorklistsetting) {
         
           try {
            const payload ={
              user_id:user.user_id,
              customer_id:key,
              setting:this.selectedWorklistsetting[key]
            }
            await userAdministrationWebServices.updateUserSetting(payload)

            } catch (error) {
              Vue.$log.error("Error while saving user setting", error)
            }
        }
      }
      if(Object.keys(this.seletedCustomerSignature).length>0){
         for (const key in this.seletedCustomerSignature) {
         
           try {
             await userAdministrationWebServices.updateSignature(user.user_id,key,new Blob([this.seletedCustomerSignature[key].file]), this.seletedCustomerSignature[key].fileName)

            } catch (error) {
              Vue.$log.error("Error while saving user signature", error)
            }
        }
      }
    }
    
  },
};
</script>

<style>
.white-font {
  color: #fff;
}

.user-administration-dropdown .dropdown-menu {
  max-height: 500px;
  overflow-y: auto;
  width: 100%;
}
</style>

<style scoped>
.no-spinners::-webkit-inner-spin-button,
.no-spinners::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.no-spinners {
  -moz-appearance:textfield;
}
</style>